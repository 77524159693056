.App {
    text-align: center;
}

#root {
    position: relative;
    height: 100vh;
}

.nadpisDne {
    font-size: 11px;
    width: 65px;
    padding-left: 0px !important;
    text-align: right;
}

.calendarItem {
    font-size: 11px;
    padding: 0px 2.5px !important;

}
.itemTime {
    font-weight: bold !important;
}

.closetrue {
    background-size: cover;
    background: url("img/krizek.png") #EED2CC !important;
}

.closeundefined {
    background: #74c69d !important;
    cursor: pointer;
}


.den0 {
    background: #cecece !important;
}

.den0.closetrue {
    background: url("img/krizek.png") #cecece !important;

}

.den1 {
    background: #fff !important;
}
.den1.closetrue {
    background: url("img/krizek.png") #ffffff !important;
}


.den2 {
    background: #ffd23f !important;
}

.den2.closetrue {
    background-size: cover;
    background: url("img/krizek.png") rgba(255, 210, 63, 1) !important;
}

.den3 {
    background: #8b79dc !important;
}

.den3.closetrue {
    background-size: cover;
    background: url("img/krizek.png") rgba(139, 121, 220, 1) !important;

}

.den4 {
    background: #f4545c !important;
}

.den4.closetrue {
    background-size: cover;
    background: url("img/krizek.png") rgba(244, 84, 92, .8) !important;
}

.den5 {
    background: #30ba3e !important;
}

.den5.closetrue {
    background-size: cover;
    background: url("img/krizek.png") rgba(48, 186, 62, 0.62) !important;
}
.den6 {
    background: #ef366d !important;
}

.den6.closetrue {
    background-size: cover;
    background: url("img/krizek.png") rgba(217, 11, 72, 0.38) !important;
}

.legendaItem {
    height: 15px;
    width: 15px;
    margin-top: 7px;
    padding: 5px !important;
    border: solid 1px #f1f1f1;
}

.legendText {
    padding: 5px !important;
    font-size: 12px;
}

.preloader {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    background: rgba(0, 0, 0, .9);
    text-align: center;
}

.preloader.visiblefalse {
    display: none;
}

.preloader > div {
    top: 49%;
    left: 50%;
    position: relative;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
.legendaItem ,.p-3.legendaItem {
    height: 15px;
    width: 15px;
    margin-top: 7px;
    padding: 5px!important;
    border: 1px solid #f1f1f1;
}


@media screen and (max-width: 1225px) {
    .d-flex.flex-row.mb-12.align-content-start.justify-content-start.pl-5.pt-1.ml-5.legenda{
        margin-left: 0px!important;
        padding-left: 60px!important;
    }
    .p-3.ml-5.text-center.align-items-center.legendaItem{
        padding-left: 5px!important;
        margin-left: 5px!important;
    }
    #root {
        font-size: 10px;
    }

    .nadpisDne {
        font-size: 11px;
    }

    .calendarItem {
        font-size: 11px;
        width: 29.5px;
        padding: 0 !important;

    }

    .itemTime {
        font-weight: bold !important;
        transform-origin: 0 0;
        transform: rotate(-45deg);
        display: block;
        position: relative;
        height: 45px;
        left: -3px;
        top:50%;
        -webkit-transform: rotate(-45deg);


        -moz-transform: rotate(-45deg);


        -ms-transform: rotate(-45deg);


        -o-transform: rotate(-45deg);


        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

    }

}

@media screen and (max-width: 1024px) {

    #root {
        font-size: 10px;
    }


}

@media screen and (max-width: 1023px) {

    #root {
        font-size: 10px;
    }
    .itemTime {
        font-weight: bold !important;
        transform-origin: 0 0;
        transform: rotate(0deg);
        display: block;
        position: relative;
        height: auto;
        top: auto;
        left: 0;
        -webkit-transform: rotate(0deg);


        -moz-transform: rotate(0deg);


        -ms-transform: rotate(0deg);


        -o-transform: rotate(0deg);


        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

    }
    .d-flex.flex-row.mb-12 {
        flex-direction: column !important;
    }

    .d-flex.flex-row.mb-12.legenda,
    .d-flex.flex-row.mb-12.w-75.justify-content-center {
        flex-direction: row !important;

    }

    .d-flex.flex-row.mb-12.w-75.align-content-start.justify-content-start.pl-5.pt-1.ml-5.legenda {
        padding: 0px !important;
        margin: 0 !important;
        display: none !important;
    }

    .calendarItem {
        font-size: 14px;
        width: auto !important;
        height: auto !important;
    }

}

@media screen and (max-width: 768px) {
    .calendarItem.den0 {
        display: none;
    }

    #root {
        font-size: 16px;
    }

    .d-flex.flex-row.mb-12 {
        flex-direction: column !important;
    }

    .d-flex.flex-row.mb-12.legenda,
    .d-flex.flex-row.mb-12.w-75.justify-content-center {
        flex-direction: row !important;

    }

    .d-flex.flex-row.mb-12.w-75.align-content-start.justify-content-start.pl-5.pt-1.ml-5.legenda {
        padding: 0px !important;
        margin: 0 !important;
        display: none !important;
    }

    .calendarItem {
        font-size: 14px;
        width: auto !important;
        height: auto !important;
    }

    .legenda {
        display: none !important;
    }
}

@media screen and (max-width: 768px) {
    .nadpisDne {
        width: 120px;
        text-align: left;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.login-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    align-content: flex-start;
    margin: 20px;
}

.btn, input[type="email"], input[type="password"], input[type="text"] {
    border-radius: 0;
}